$border-color: #ccc;
$hover-bg-color: #c3abff;
$box-shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
$box-shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
$border-radius-sm: 0.5rem;
$border-radius-lg: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-xs: 12px;
$padding-sm: 0.25rem;
$padding-md: 0.375rem;
$padding-lg: 0.5rem;
$padding-xl: 1rem;
$margin-sm: 0.25rem;
$margin-md: 0.5rem;
$margin-lg: 1rem;
$color-gray: gray;
$color-black: black;
$color-white: white;

.create-container {
  position: relative;
  color: $color-black;
  margin: 0 $margin-md;
  display: inline-block;
  text-align: left;

  .create-button {
    border: 1px solid;
    display: flex;
    padding: $padding-lg $padding-xl;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: $border-radius-lg;
    background-color: transparent;
    box-shadow: $box-shadow-sm;

    span {
      font-weight: 500;
    }

    .icon {
      margin-left: $margin-md;
      font-size: 16px;
    }
  }

  .dropdown-container {
    position: absolute;
    top: 20px;
    height: 1.25rem;
    width: 100%;
  }

  .dropdown-menu {
    border: 1px solid $border-color;
    position: absolute;
    left: 0;
    margin-top: $margin-md;
    max-height: 80vh;
    overflow: auto;
    width: 88vw;
    max-width: 550px;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-lg;
    background-color: $color-white;

    .menu-content {
      padding: $padding-sm $padding-xl;

      .menu-section {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;

        .menu-section-title {
          color: $color-black;
          margin-bottom: $margin-sm;
          margin-top: $margin-sm;
          font-weight: bold;
        }

        .menu-items {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          .menu-item {
            display: flex;
            align-items: center;
            border-radius: $border-radius-sm;
            padding: $padding-md $padding-sm;
            font-size: $font-size-sm;
            color: $color-gray;
            text-decoration: none;
            background-color: transparent;

            &:hover {
              background-color: $hover-bg-color;
              color: $color-gray;
            }

            .icon {
              margin-right: $margin-lg;
            }

            span {
              font-weight: 500;
              color: $color-black;
            }

            .tag {
              font-size: $font-size-xs;
            }
          }
        }
      }
    }
  }
}
